<div class="container">
  <h1 class="text-center display-4">Lista de interessados no livro '{{ book.title }}'</h1>

  <div class="alert alert-warning" role="alert" [hidden]="!showWarning">
    {{ warningMessage }}
  </div>

  <div class="alert alert-success" role="alert" [hidden]="!showWarningWinnerChoosed">
    Você já escolheu o ganhador. =)
  </div>

  <div class="container mt-5" matSort>
    <div fxLayout fxLayoutAlign="center center">
      <mat-form-field>
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Pesquisar" />
      </mat-form-field>
    </div>

    <table mat-table [dataSource]="donateUsers" class="mat-elevation-z1">
      <ng-container matColumnDef="requesterNickName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Apelido</th>
        <td mat-cell *matCellDef="let element">{{ element.requesterNickName }}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Destino</th>
        <td mat-cell *matCellDef="let element">{{ element.location }}</td>
      </ng-container>

      <ng-container matColumnDef="totalBooksWon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Livros Ganhos</th>
        <td mat-cell *matCellDef="let element">{{ element.totalBooksWon }}</td>
      </ng-container>

      <ng-container matColumnDef="totalBooksDonated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Livros Doados</th>
        <td mat-cell *matCellDef="let element">{{ element.totalBooksDonated }}</td>
      </ng-container>

      <ng-container matColumnDef="requestText">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Motivo</th>
        <td mat-cell *matCellDef="let element">{{ element.requestText }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="header">Ação</th>
        <td mat-cell *matCellDef="let element">
          <button
            style="background-color: #ffc107"
            mat-flat-button
            (click)="onCustom('donate', element)"
            class="ml-1 mb-1"
            data-toggle="tooltip"
            title="Escolher ganhador"
          >
            <mat-icon>emoji_events</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="empty-data" *ngIf="donateUsers.data.length === 0 && (isLoading$ | async) === false">
      Nenhum registro encontrado.
    </div>

    <div class="spinner-container" *ngIf="isLoading$ | async">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <button mat-flat-button color="primary" (click)="back()" title="Voltar">
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>
