<div class="close">
  <i class="fa fa-times" aria-hidden="true" mat-dialog-close></i>
</div>
<h1 mat-dialog-title>Espere! 🙏</h1>
<mat-dialog-content>
  <p>
    Sabia que com o <strong>registro módico</strong> o envio de livros para todo Brasil fica baratinho? Em média apenas
    <strong>dez reais</strong>.
  </p>
  <p>
    Mais detalhes,
    <a href="https://www.linkedin.com/feed/update/urn:li:activity:6447871003954540544" (click)="AbrirRegistroModico()"
      target="_blank" rel="noopener">clique aqui.</a>
  </p>
</mat-dialog-content>
