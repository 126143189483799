<div class="close">
  <i class="fa fa-times" aria-hidden="true" mat-dialog-close></i>
</div>
<h4 class="modal-title">Informe o código de rastreio para o livro:</h4>
<form [formGroup]="formGroup" (ngSubmit)="onTracking()">
  <mat-dialog-content>
    <h4>{{ bookTitle }}</h4>
    <div class="text-center" [hidden]="!isLoading"><i class="fa fa-spinner fa-spin"></i> Aguarde...</div>
    <div class="text-center" *ngIf="state == 'request-error'">
      <p>:/ ocorreu um erro.</p>
      <div class="alert alert-danger" role="alert">
        {{ lastError }}
      </div>
    </div>
    <textarea
      formControlName="trackingNumber"
      name="trackingNumber"
      type="text"
      id="trackingNumber"
      class="md-textarea form-control"
      rows="3"
      maxlength="2000"
    ></textarea>
    <div *ngIf="formGroup.controls['trackingNumber'].touched && !formGroup.controls['trackingNumber'].valid">
      <small class="form-text text-danger" *ngIf="formGroup.controls['trackingNumber'].hasError('required')">
        Informe o código de rastreio.
      </small>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="!formGroup.valid" type="submit">Confirmar</button>
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
  </mat-dialog-actions>
</form>
