<form [formGroup]="searchForm" (ngSubmit)="search()">
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      formControlName="paramSearch"
      id="inputSearch"
      placeholder="Digite aqui sua pesquisa"
      aria-label="Digite aqui sua pesquisa"
    />
    <div class="input-group-append">
      <button mat-stroked-button color="primary" type="submit" id="button-addon2">Pesquisar</button>
    </div>
  </div>
  <div
        *ngIf="searchAlert"
        #alert
        class="alert alert-warning alert-dismissible fade show"
        role="alert"
      >
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeAlert()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <strong>Você deve incluir uma palavra na pesquisa</strong>
      </div>
</form>
