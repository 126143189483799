<div class="container">
  <h1 class="text-center display-4">
    Gerenciar Livros
    <button style="bottom: 10px" mat-mini-fab color="primary" (click)="reloadData()">
      <mat-icon>cached</mat-icon>
    </button>
  </h1>

  <div class="container mt-5">
    <div fxLayout fxLayoutAlign="center center">
      <mat-form-field>
        <input matInput type="text" (keyup)="search($event.target.value)" placeholder="Pesquisar" />
      </mat-form-field>
      &nbsp;&nbsp;

      <mat-form-field>
        <select matNativeControl (change)="searchByStatus($event.target.value)" id="selectSearchByStatus">
          <option value="">Busca por Status</option>
          <option *ngFor="let status of statusSearchValues; let i = index" [value]="statusSearchValues[i].value">
            {{ statusSearchValues[i].title }}
          </option>
        </select>
      </mat-form-field>
    </div>
    <table
      mat-table
      [dataSource]="myBookArray"
      matSort
      matSortActive="creationDate"
      matSortDirection="desc"
      matSortDisableClear
      class="mat-elevation-z1"
    >
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Inclusão</th>
        <td mat-cell *matCellDef="let element">
          <font [color]="getTextColor(element.status)">
            {{ element.creationDate | date: 'dd/MM/yyyy' }}
          </font>
        </td>
      </ng-container>

      <ng-container matColumnDef="chooseDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Escolha</th>
        <td mat-cell *matCellDef="let element">
          <font [color]="getTextColor(element.status)">
            {{ element.chooseDate | date: 'dd/MM/yyyy' }}
          </font>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">
          Título / Autor / Total Interessados / Dias na Vitrine
        </th>
        <td mat-cell *matCellDef="let element">
          <font [color]="getTextColor(element.status)">
            {{ element.title }}, <br />
            {{ element.author }}, <br />
            {{ element.totalInterested }} interessado(s), <br />
            {{ element.daysInShowcase }} dia(s) na vitrine
          </font>
        </td>
      </ng-container>

      <ng-container matColumnDef="users">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Doador / Donatário / Facilitador</th>
        <td mat-cell *matCellDef="let element">
          <font [color]="getTextColor(element.status)">
            Doador: {{ element.donor }} <br />
            Donatário: {{ element.winner }} <br />
            Facilitador: {{ element.facilitator }}
          </font>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Status</th>
        <td mat-cell *matCellDef="let element">
          <font [color]="getTextColor(element.status)">
            {{ getTranslatedStatusDescription(element.status) }}
          </font>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="header">Ação</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-flat-button
            color="primary"
            (click)="onCustom('edit', element)"
            data-toggle="tooltip"
            title="Editar Livro"
          >
            <mat-icon>edit_note</mat-icon>
          </button>

          <button
            (click)="onCustom('cancelDonation', element)"
            mat-flat-button
            class="ml-1 mb-1"
            color="warn"
            data-toggle="tooltip"
            title="Cancelar Doação"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>

          <button
            (click)="onCustom('donate', element)"
            mat-flat-button
            class="ml-1 mb-1"
            style="background-color: #ffc107"
            data-toggle="tooltip"
            title="Ver lista de interessados"
          >
            <mat-icon>format_list_bulleted</mat-icon>
          </button>

          <button
            (click)="onCustom('facilitatorNotes', element)"
            mat-flat-button
            class="ml-1 mb-1"
            style="background-color: #17a2b8; color: white"
            data-toggle="tooltip"
            title="Informar Comentários"
          >
            <mat-icon>sticky_note_2</mat-icon>
          </button>

          <button
            (click)="onCustom('trackNumber', element)"
            mat-flat-button
            style="background-color: gray; color: white"
            class="ml-1 mb-1"
            data-toggle="tooltip"
            title="Informar Código Rastreio"
          >
            <mat-icon>local_shipping</mat-icon>
          </button>

          <button
            (click)="onCustom('showUsersInfo', element)"
            mat-flat-button
            class="ml-1 mb-1"
            data-toggle="tooltip"
            title="Informações de Usuários"
          >
            <mat-icon>group</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>

    <div class="empty-data" *ngIf="myBookArray.data.length === 0 && (isLoading$ | async) === false">
      Nenhum registro encontrado.
    </div>

    <div class="spinner-container" *ngIf="isLoading$ | async">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
