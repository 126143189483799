<div class="container">
  <h1 class="text-center display-4">Minhas Doações</h1>
  <div class="container mt-5" matSort>
    <div fxLayout fxLayoutAlign="center center">
      <mat-form-field>
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Pesquisar" />
      </mat-form-field>
    </div>
    <table mat-table [dataSource]="donatedBooks" class="mat-elevation-z1">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Titulo</th>
        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
      </ng-container>

      <ng-container matColumnDef="totalInterested">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Total interessados</th>
        <td mat-cell *matCellDef="let element">{{ element.totalInterested }}</td>
      </ng-container>

      <ng-container matColumnDef="daysInShowcase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Dias na vitrine</th>
        <td mat-cell *matCellDef="let element">{{ element.daysInShowcase }}</td>
      </ng-container>

      <ng-container matColumnDef="chooseDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Data Escolha</th>
        <td mat-cell *matCellDef="let element">{{ element.chooseDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="trackingNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Código Ratreio</th>
        <td mat-cell *matCellDef="let element">{{ element.trackingNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Status</th>
        <td mat-cell *matCellDef="let element">
          <span
            class="custom-badge"
            [ngStyle]="{
              backgroundColor: getStatusBadgeBackgroundColor(element.status),
              color: getStatusBadgeTextColor(element.status)
            }"
            >{{ getTranslatedStatusDescription(element.status) }}</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="header">Ação</th>
        <td mat-cell *matCellDef="let element">
          <button
            (click)="onCustom('donate', element)"
            mat-flat-button
            class="ml-1 mb-1"
            style="background-color: #ffc107"
            data-toggle="tooltip"
            title="Ver interessados"
          >
            <mat-icon>format_list_bulleted</mat-icon>
          </button>

          <button
            (click)="onCustom('renewChooseDate', element)"
            mat-flat-button
            class="ml-1 mb-1"
            style="background-color: #17a2b8; color: white"
            data-toggle="tooltip"
            title="Renovar Data de Escolha"
          >
            <mat-icon>calendar_month</mat-icon>
          </button>

          <button
            (click)="onCustom('trackNumber', element)"
            mat-flat-button
            style="background-color: gray; color: white"
            class="ml-1 mb-1"
            data-toggle="tooltip"
            title="Informar Código Rastreio"
          >
            <mat-icon>local_shipping</mat-icon>
          </button>

          <button
            (click)="onCustom('CancelDonation', element)"
            mat-flat-button
            class="ml-1 mb-1"
            color="warn"
            data-toggle="tooltip"
            title="Cancelar Doação"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>

          <button
            (click)="onCustom('ShowWinnerInfo', element)"
            mat-flat-button
            class="ml-1 mb-1"
            data-toggle="tooltip"
            title="Ver ganhador"
          >
            <mat-icon>person</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="empty-data" *ngIf="donatedBooks.data.length === 0 && (isLoading$ | async) === false">
      Nenhum registro encontrado.
    </div>

    <div class="spinner-container" *ngIf="isLoading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <p class="mt-2">
      <button mat-button color="primary" routerLink="/panel">Voltar</button>
    </p>
  </div>
</div>
