<div class="meetup">
  <a href="{{ GetMeetupUrl() }}" target="_blank" rel="noopener">
    <img src="{{ meetup.cover }}" alt="{{ meetup.title }}" />
    <span class="calendar" *ngIf="isUpcoming">
      <br />
      <i class="fa fa-calendar"></i> {{ meetup.startDate | date : 'E d LLL HH:mm' : '' : 'pt-BR' }}
      <br />
    </span>
    {{ meetup.title }}
  </a>
</div>
