<div class="container">
  <h1 class="text-center display-4">Meus Pedidos</h1>

  <div class="container mt-5" matSort>
    <div fxLayout fxLayoutAlign="center center">
      <mat-form-field>
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Pesquisar" />
      </mat-form-field>
    </div>
    <table *ngIf="(isLoading$ | async) === false" mat-table [dataSource]="requestedBooks" class="mat-elevation-z1">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Titulo</th>
        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
      </ng-container>

      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Autor</th>
        <td mat-cell *matCellDef="let element">{{ element.author }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">Status</th>
        <td mat-cell *matCellDef="let element">
          <span
            class="custom-badge"
            [ngStyle]="{
              backgroundColor: getStatusBadgeBackgroundColor(element.status),
              color: getStatusBadgeTextColor(element.status)
            }"
            >{{ getTranslatedStatusDescription(element.status) }}</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="doador">
        <th mat-header-cell *matHeaderCellDef class="header">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            (click)="onCustomActionColum('verDoador', element)"
            mat-flat-button
            class="ml-1 mb-1"
            data-toggle="tooltip"
            title="Ver doador"
            *ngIf="showIconDonor(element)"
          >
            <mat-icon>person</mat-icon>
          </button>

          <button
            *ngIf="showIconCancel(element)"
            (click)="onCustomActionColum('cancelarPedido', element)"
            mat-flat-button
            class="ml-1 mb-1"
            color="warn"
            data-toggle="tooltip"
            title="Cancelar Pedido"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="empty-data" *ngIf="requestedBooks.data.length === 0 && (isLoading$ | async) === false">
      Nenhum registro encontrado.
    </div>

    <div class="spinner-container" *ngIf="isLoading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <p class="mt-2">
      <button mat-button color="primary" routerLink="/panel">Voltar</button>
    </p>
  </div>
</div>
