<div class="close">
  <i class="fa fa-times" aria-hidden="true" mat-dialog-close></i>
</div>
<form [formGroup]="formGroup" class="container form-horizontal" (ngSubmit)="onDonate()">
  <mat-dialog-content>
    <h4 class="modal-title">Motivo da Doação para {{ userNickName }}</h4>
    <div class="text-center" [hidden]="!isLoading"><i class="fa fa-spinner fa-spin"></i> Aguarde...</div>

    <textarea
      formControlName="myNote"
      name="myNote"
      type="text"
      id="myNote"
      class="md-textarea form-control"
      rows="3"
      maxlength="2000"
    ></textarea>
    <div *ngIf="formGroup.controls['myNote'].touched && !formGroup.controls['myNote'].valid">
      <small class="form-text text-danger" *ngIf="formGroup.controls['myNote'].hasError('required')">
        Informe o motivo da doação.
      </small>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="!formGroup.valid" type="submit">Doar</button>
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
  </mat-dialog-actions>
</form>
