<form [formGroup]="formGroup" class="container form-horizontal" (ngSubmit)="registerUser()">
  <h1 class="text-center display-4">Registro</h1>
  <div class="form-row">
    <div class="col">
      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="name">Nome</label>
        <div class="input-group">
          <input formControlName="name" id="name" name="name" type="text" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-address-book"></i>
          </div>
        </div>
        <div *ngIf="formGroup.controls['name'].touched && !formGroup.controls['name'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['name'].hasError('required')">
            Nome obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['name'].hasError('minlength')">
            O nome deve conter no mínimo 3 caracteres.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['name'].hasError('maxlength')">
            O nome deve conter no máximo 200 caracteres.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="name">Idade</label>
        <div class="input-group">
          <input formControlName="age" id="age" name="age" type="number" step="1" class="form-control" />
          <div class="input-group-addon append"><i class="fa fa-address-book" aria-hidden="true"></i></div>
        </div>
        <div *ngIf="formGroup.controls['age'].touched && !formGroup.controls['age'].valid">
          <small class="form-text text-danger"> Não parece ser uma idade válida. Tente entre 8 e 100. </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2" *ngIf="isMinor">
        <label class="col-form-label" for="name">Email dos pais ou responsável</label>
        <div class="input-group">
          <input formControlName="parentEmail" id="parentEmail" name="parentEmail" type="text" class="form-control" />
          <div class="input-group-addon append"><i class="fa fa-envelope-square" aria-hidden="true"></i></div>
        </div>
        <div *ngIf="formGroup.controls['parentEmail'].touched && !formGroup.controls['parentEmail'].valid">
          <small class="form-text text-danger"> O e-mail deve ser um e-mail válido. </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="email">E-mail</label>
        <div class="input-group">
          <input formControlName="email" id="email" type="text" class="form-control lowerCase" />
          <div class="input-group-addon append">
            <i class="fa fa-envelope-square"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['email'].touched && !formGroup.controls['email'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('required')">
            E-mail obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('pattern')">
            O e-mail deve ser um e-mail válido.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="password">Senha</label>
        <div class="input-group">
          <input formControlName="password" id="password" type="password" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-unlock-alt"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['password'].touched && !formGroup.controls['password'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['password'].hasError('required')">
            Senha obrigatória.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['password'].hasError('minlength')">
            A senha deve conter no mínimo 6 caracteres.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['password'].hasError('maxlength')">
            A senha deve conter no máximo 32 caracteres.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="confirmPassword">Confirmar Senha</label>
        <div class="input-group">
          <input formControlName="confirmPassword" id="confirmPassword" type="password" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-unlock-alt"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['confirmPassword'].touched && !formGroup.controls['confirmPassword'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['confirmPassword'].hasError('required')">
            Confirmação de senha obrigatória.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['confirmPassword'].hasError('MatchPassword')">
            A confirmação de senha não confere com a senha informada.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="phone">DDD + Telefone</label>
        <div class="input-group">
          <input
            formControlName="phone"
            id="phone"
            type="text"
            class="form-control"
            [dropSpecialCharacters]="false"
            mask="(00) 00000-0000"
          />
          <div class="input-group-addon append">
            <i class="fa fa-whatsapp"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['phone'].touched && !formGroup.controls['phone'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['phone'].hasError('required')">
            Telefone obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['phone'].hasError('pattern')">
            Telefone deve estar no formato (99) 99999-9999
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="postalCode">CEP</label>
        <div class="input-group">
          <input
            formControlName="postalCode"
            id="postalCode"
            type="text"
            [dropSpecialCharacters]="false"
            mask="00000-000"
            class="form-control"
            (blur)="getAddressByPostalCode($event.target.value)"
          />

          <div class="input-group-addon append" [hidden]="isGettingAddress">
            <i class="fa fa-map-marker"></i>
          </div>
          <div [hidden]="!isGettingAddress" [hidden]="!isGettingAddress">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['postalCode'].touched && !formGroup.controls['postalCode'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['postalCode'].hasError('required')">
            CEP obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['postalCode'].hasError('pattern')">
            CEP deve estar no formato 99999-999.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="street">Logradouro</label>
        <div class="input-group">
          <input formControlName="street" id="street" type="text" class="form-control" maxlength="80" />
          <div class="input-group-addon append">
            <i class="fa fa-road"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['street'].touched && !formGroup.controls['street'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['street'].hasError('required')">
            Endereço obrigatório.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="number">Número</label>
        <div class="input-group">
          <input formControlName="number" id="number" type="text" class="form-control" maxlength="10" />
          <div class="input-group-addon append">
            <i class="fa fa-map-marker"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['number'].touched && !formGroup.controls['number'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['number'].hasError('required')">
            Número obrigatório.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="complement">Complemento</label>
        <div class="input-group">
          <input formControlName="complement" id="complement" type="text" class="form-control" maxlength="50" />
          <div class="input-group-addon append">
            <i class="fa fa-map-marker"></i>
          </div>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="neighborhood">Bairro</label>
        <div class="input-group">
          <input formControlName="neighborhood" id="neighborhood" type="text" class="form-control" maxlength="50" />
          <div class="input-group-addon append">
            <i class="fa fa-map-marker"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['neighborhood'].touched && !formGroup.controls['neighborhood'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['neighborhood'].hasError('required')">
            Bairro obrigatório.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="city">Cidade</label>
        <div class="input-group">
          <input formControlName="city" id="city" type="text" class="form-control" maxlength="50" />
          <div class="input-group-addon append">
            <i class="fa fa-map-marker"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['city'].touched && !formGroup.controls['city'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['city'].hasError('required')">
            Cidade obrigatório.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="state">Estado</label>
        <div class="input-group">
          <input formControlName="state" id="state" type="text" class="form-control" maxlength="30" />
          <div class="input-group-addon append">
            <i class="fa fa-map-marker"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['state'].touched && !formGroup.controls['state'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['state'].hasError('required')">
            Estado obrigatório.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="country">País</label>
        <div class="input-group">
          <input formControlName="country" id="country" type="text" class="form-control" maxlength="50" />
          <div class="input-group-addon append">
            <i class="fa fa-globe"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['country'].touched && !formGroup.controls['country'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['country'].hasError('required')">
            País obrigatório.
          </small>
        </div>
      </div>
      <div class="form-group col-md-6 offset-md-3 mb-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="allowSendingEmail" id="allowSendingEmail" />
          <label class="form-check-label" for="allowSendingEmail">
            Aceito receber e-mails e newletter do Sharebook
          </label>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="acceptTermOfUse"
            id="defaultCheck2"
            required
            defaultCheck2
          />
          <label class="form-check-label" for="defaultCheck2">
            Aceito os <a routerLink="/termos-de-uso">termos de uso</a>
          </label>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <div class="text-center" style="margin-left: 100px">
          <re-captcha formControlName="recaptchaReactive"></re-captcha>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3">
        <div class="text-center">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            style="padding-left: 50px; padding-right: 50px"
            [disabled]="!formGroup.valid"
          >
            Registrar
          </button>
          <button [routerLink]="['/login']" mat-button color="primary">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</form>
