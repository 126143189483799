<div class="close">
  <em class="fa fa-times" aria-hidden="true" mat-dialog-close></em>
</div>
<h4 class="modal-title">Informações de usuários para o livro:</h4>
<mat-dialog-content>
  <h4>{{ bookTitle }}</h4>
  <div class="text-center" [hidden]="!isLoading"><em class="fa fa-spinner fa-spin"></em> Aguarde...</div>
  <!--Carousel Wrapper-->
  <div id="myCarousel" class="carousel carousel-fade" data-ride="carousel">
    <!--Indicators-->
    <ol class="carousel-indicators">
      <li
        data-target="#myCarousel"
        *ngFor="let winnerUser of winnerUsers; let i = index"
        [attr.data-slide-to]="i"
        [ngClass]="i == 0 ? 'active' : ''"
      ></li>
    </ol>
    <!--/.Indicators-->
    <!--Slides-->
    <div class="carousel-inner" role="listbox">
      <div
        *ngFor="let winnerUser of winnerUsers; let k = index"
        [ngClass]="k == 0 ? 'carousel-item active' : 'carousel-item'"
      >
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">Vencedor</h5>
            <p class="card-text">Nome: {{ winnerUser.name }}</p>
            <p class="card-text">E-mail: {{ winnerUser.email }}</p>
            <p class="card-text">LinkedIn: {{ winnerUser.linkedin }}</p>
            <p class="card-text">Telefone: {{ winnerUser.phone }}</p>
            <p class="card-text">
              {{ winnerUser.address?.street }}, {{ winnerUser.address?.number }}
              {{ !!winnerUser.address?.complement ? winnerUser.address?.complement : '' }}
            </p>
            <p class="card-text">
              {{ winnerUser.address?.neighborhood }} - {{ winnerUser.address?.city }} - {{ winnerUser.address?.state }}
            </p>
            <p class="card-text">CEP: {{ winnerUser.address?.postalCode }} - {{ winnerUser.address?.country }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--/.Slides-->
  </div>
  <!--/.Carousel Wrapper-->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close>Ok entendi</button>
</mat-dialog-actions>
