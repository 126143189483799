<div class="close">
  <i class="fa fa-times" aria-hidden="true" mat-dialog-close></i>
</div>
<h4 class="modal-title">Informações do doador do livro:</h4>
<mat-dialog-content>
  <h4>{{ bookTitle }}</h4>
  <section class="card-body">
    <div [hidden]="!loading">
      <div class="text-center"><em class="fa fa-spinner fa-spin"></em> Aguarde...</div>
    </div>

    <ng-container *ngIf="messageBody !== ''">
      <h5 class="card-title">{{ messageBody }}</h5>
    </ng-container>

    <ng-container *ngIf="(userInfo$ | async)?.donor as donor">
      <h5 class="card-title">Doador</h5>
      <p class="card-text">Nome: {{ donor.name }}</p>
      <p class="card-text">E-mail: {{ donor.email }}</p>
      <p class="card-text">LinkedIn: {{ donor.linkedin }}</p>
      <p class="card-text">Telefone: {{ donor.phone }}</p>
    </ng-container>
  </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close>Ok entendi</button>
</mat-dialog-actions>
