<form [formGroup]="formGroup" class="container form-horizontal" (ngSubmit)="loginUser()">
  <h1 class="text-center display-4">Login</h1>
  <div class="form-row">
    <div class="col">
      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="email">E-mail</label>
        <div class="input-group">
          <input formControlName="email" id="txtEmail" type="text" class="form-control lowerCase" />
          <div class="input-group-addon append">
            <i class="fa fa-envelope-square"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['email'].touched && !formGroup.controls['email'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('required')">
            E-mail obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('pattern')">
            O e-mail deve ser um e-mail válido.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="password">Senha</label>
        <div class="input-group">
          <input formControlName="password" id="txtPassword" type="password" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-unlock-alt"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['password'].touched && !formGroup.controls['password'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['password'].hasError('required')">
            Senha obrigatória.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3">
        <div class="input-group">
          <button
            type="submit"
            id="btnLogin"
            style="padding-left: 50px; padding-right: 50px; width: inherit;"
            [disabled]="!formGroup.valid"
            mat-raised-button
            color="primary"
          >
            Login
          </button>
        </div>
        <div class="text-center">
          <p>
            <a [routerLink]="['/register']" mat-button color="primary">Criar Conta</a> ou
            <a [routerLink]="['/reset-password']" mat-button color="primary">Esqueci minha senha</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</form>
