<div class="container">
  <h1 class="text-center display-4">Consentimento dos pais ou responsável</h1>

  <p>
    Olá. Conforme notificado no email, precisamos do seu consentimento para liberar o acesso em nosso app. Sharebook é
    um app livre e gratuito para doação de livros. Temos todo o cuidado com a segurança e privacidade dos nossos
    usuários.
  </p>

  <div class="form-row" *ngIf="state == 'ready'">
    <div class="col">
      <div class="form-group col-md-6 offset-md-3">
        <div class="text-center">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            style="padding-left: 50px; padding-right: 50px"
            (click)="parentAproval()"
          >
            Liberar acesso
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-success" role="alert" *ngIf="state == 'approved'">Acesso liberado com sucesso.</div>

  <div *ngIf="state == 'loading'"><mat-spinner></mat-spinner></div>

  <div class="alert alert-danger" role="alert" *ngIf="state == 'error'">{{ errorMsg }}</div>
</div>
