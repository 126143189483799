<form [formGroup]="formGroup" class="container form-horizontal" (ngSubmit)="changePassword()">
  <h1 class="text-center display-4">Alterar Senha</h1>
  <div class="form-row">
    <div class="col">
      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="oldPassword">Senha Atual</label>
        <div class="input-group">
          <input formControlName="oldPassword" type="password" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-unlock-alt"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['oldPassword'].touched && !formGroup.controls['oldPassword'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['oldPassword'].hasError('required')">
            Senha atual obrigatória.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="newPassword">Nova Senha</label>
        <div class="input-group">
          <input formControlName="newPassword" type="password" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-unlock-alt"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['newPassword'].touched && !formGroup.controls['newPassword'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['newPassword'].hasError('required')">
            Nova senha obrigatória.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['newPassword'].hasError('minlength')">
            A senha deve conter no mínimo 6 caracteres.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['newPassword'].hasError('maxlength')">
            A senha deve conter no máximo 32 caracteres.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="confirmPassword">Confirmar Nova Senha</label>
        <div class="input-group">
          <input formControlName="confirmPassword" type="password" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-unlock-alt"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['confirmPassword'].touched && !formGroup.controls['confirmPassword'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['confirmPassword'].hasError('required')">
            Confirmação de senha obrigatória.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['confirmPassword'].hasError('MatchPassword')">
            A confirmação de senha não confere com a nova senha informada.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3">
        <div class="text-center">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            style="padding-left: 50px; padding-right: 50px"
            [disabled]="!formGroup.valid"
          >
            Atualizar
          </button>
          <button mat-button color="primary" [routerLink]="['/panel']">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</form>
