<div class="close">
  <i class="fa fa-times" aria-hidden="true" mat-dialog-close></i>
</div>
<mat-dialog-content>
  <h4>{{ data.title }}</h4>
  {{ data.message }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="true" color="primary" type="submit">{{ data.btnOkText }}</button>
  <button *ngIf="data.btnCancelText !== ''" mat-stroked-button [mat-dialog-close]="false">{{ data.btnCancelText }}</button>
</mat-dialog-actions>
