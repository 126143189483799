<div class="container form-horizontal">
  <h1 class="text-center display-4">{{ pageTitle }}</h1>

  <div *ngIf="state == 'not-found'" class="text-center">
    <button mat-stroked-button color="primary" style="margin: 0 3px 3px 0" routerLink="/">Voltar para página inicial</button>
  </div>

  <div class="text-center" *ngIf="state == 'loading'"><em class="fa fa-spinner fa-spin"></em> Aguarde...</div>

  <div class="container" *ngIf="state == 'ready'">
    <div class="form-row">
      <div class="form-group col-lg-4">
        <img
          [src]="
            bookInfo.imageBytes?.length
              ? 'data:image/png;base64,' + bookInfo.imageBytes
              : bookInfo.imageUrl
              ? bookInfo.imageUrl
              : 'assets/img/img-placeholder.png'
          "
          class="img-thumbnail"
          alt="Book image"
        />
      </div>
      <div class="form-group col-lg-8">
        <p><strong>Título:</strong><br />{{ bookInfo.title }}</p>
        <p><strong>Autor:</strong><br />{{ bookInfo.author }}</p>
        <p><strong>Categoria:</strong><br />{{ bookInfo.category }}</p>
        <p *ngIf="!!bookInfo.synopsis" style="white-space: pre-wrap">
          <strong>Sinópse:</strong><br />{{ bookInfo.synopsis }}
        </p>
        <p *ngIf="available">
          <strong>Quando será anunciado o ganhador desse livro?</strong><br />{{ chooseDateInfo }}
        </p>
        <p>
          <strong>Local de Origem</strong><br />{{ bookInfo.city }} -
          {{ bookInfo.state }}
        </p>
        <p *ngIf="available && userProfile"><strong>Frete grátis?</strong><br />{{ isFreeFreight ? 'Sim' : 'Não' }}</p>
        <div class="custom-control custom-checkbox" *ngIf="available && userProfile && !requested && !isFreeFreight">
          <input type="checkbox" class="custom-control-input" id="checkFreight" [(ngModel)]="isCheckedFreight" />
          <label class="custom-control-label" for="checkFreight"
            >Estou ciente que o frete não é grátis. Caso seja escolhido, me comprometo a reembolsar o doador.</label
          >
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-12">
        <div class="modal-footer">
          <span class="badge badge-success" *ngIf="requested">Você já solicitou. Aguarde e boa sorte!</span>

          <span class="badge badge-danger" *ngIf="!requested && !available">Não disponível.</span>

          <button
            mat-flat-button
            color="primary"
            class="mr-2"
            *ngIf="available && userProfile && !requested"
            (click)="onRequestBook()"
            [disabled]="!isFreeFreight && !isCheckedFreight"
          >
            Tenho interesse
          </button>

          <button
            mat-flat-button
            color="primary"
            class="mr-2"
            *ngIf="available && !userProfile"
            (click)="onLoginBook()"
          >
            Faça login para solicitar o livro
          </button>

          <button mat-stroked-button color="primary" [routerLink]="['/home']" id="cancelButton">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</div>
