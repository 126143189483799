<form [formGroup]="formGroup" id="formGroup" class="container form-horizontal" (ngSubmit)="onAddBook()">
  <input formControlName="userId" id="userId" type="hidden" />
  <div *ngIf="isSaved" class="text-center">
    <h1 class="display-4">{{ pageTitle }}</h1>
    <div *ngIf="userProfile === 'User'">
      <button href="/livros/doar" mat-button color="primary" style="margin: 0 3px 3px 0">
        Doar mais um
        <mat-icon>favorite</mat-icon>
      </button>
    </div>
    <div *ngIf="userProfile === 'Administrator'">
      <button routerLink="/book/list" mat-button color="primary" style="margin: 0 3px 3px 0">Voltar ao Painel</button>
    </div>
    <button routerLink="/" mat-flat-button color="primary" style="margin: 0 3px 3px 0">
      Veja os ultimos livros doados
    </button>
  </div>

  <div *ngIf="!isSaved">
    <h1 class="text-center display-4">{{ pageTitle }}</h1>
    <div class="form-row">
      <div class="col-3 figure">
        <img
          [src]="
            formGroup.value.imageBytes.length
              ? 'data:image/png;base64,' + formGroup.value.imageBytes
              : formGroup.value.imageUrl
              ? formGroup.value.imageUrl
              : 'assets/img/img-placeholder.png'
          "
          class="figure-img img-fluid rounded"
          alt="Book image"
        />
      </div>
      <div class="col">
        <div class="form-group col-md-8">
          <label class="col-form-label" for="title">Título do livro</label>
          <input formControlName="title" id="title" name="title" type="text" class="form-control" maxlength="200" />

          <div *ngIf="formGroup.controls['title'].touched && !formGroup.controls['title'].valid">
            <small class="form-text text-danger" *ngIf="formGroup.controls['title'].hasError('required')">
              Titulo do livro obrigatório.
            </small>
            <small class="form-text text-danger" *ngIf="formGroup.controls['title'].hasError('minlength')">
              O titúlo deve conter no mínimo 3 caracteres.
            </small>
            <small class="form-text text-danger" *ngIf="formGroup.controls['title'].hasError('maxlength')">
              O titúlo deve conter no máximo 200 caracteres.
            </small>
          </div>
        </div>

        <div class="form-group col-md-8">
          <label class="col-form-label" for="author">Autor</label>
          <input formControlName="author" id="author" type="text" class="form-control" maxlength="200" />

          <div *ngIf="formGroup.controls['author'].touched && !formGroup.controls['author'].valid">
            <small class="form-text text-danger" *ngIf="formGroup.controls['author'].hasError('required')">
              Informe o nome do autor do livro.
            </small>
            <small class="form-text text-danger" *ngIf="formGroup.controls['author'].hasError('minlength')">
              O nome do autor deve conter no mínimo 3 caracteres.
            </small>
            <small class="form-text text-danger" *ngIf="formGroup.controls['author'].hasError('maxlength')">
              O nome do autor deve conter no máximo 200 caracteres.
            </small>
          </div>
        </div>

        <div class="form-group col-md-8 mb-4">
          <label class="col-form-label" for="categoryId">Categoria</label>
          <select class="form-control" formControlName="categoryId" id="categoryId">
            <option value=""></option>
            <option *ngFor="let category of categories" value="{{ category.id }}">{{ category.name }}</option>
          </select>
          <div *ngIf="formGroup.controls['categoryId'].touched && !formGroup.controls['categoryId'].valid">
            <small class="form-text text-danger" *ngIf="formGroup.controls['categoryId'].hasError('required')">
              Informe a categoria do livro.
            </small>
          </div>
        </div>

        <div class="form-group col-md-8 mb-4" *ngIf="userProfile.profile === 'Administrator' && itsEditMode">
          <label class="col-form-label" for="userIdFacilitator">Facilitador</label>
          <select class="form-control" formControlName="userIdFacilitator" id="userIdFacilitator">
            <option value=""></option>
            <option *ngFor="let facilitator of facilitators" value="{{ facilitator.id }}">
              {{ facilitator.name }}
            </option>
          </select>
          <div
            *ngIf="formGroup.controls['userIdFacilitator'].touched && !formGroup.controls['userIdFacilitator'].valid"
          >
            <small class="form-text text-danger" *ngIf="formGroup.controls['userIdFacilitator'].hasError('required')">
              Informe o facilitador do livro.
            </small>
          </div>
        </div>

        <div class="form-group col-md-8">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              formControlName="imageName"
              id="imageName"
              imageUpload
              (imageSelected)="onConvertImageToBase64($event)"
              [imageToDataUrl]="options"
            />
            <label class="custom-file-label" for="imageName" *ngIf="!formGroup.controls['imageName'].valid">
              Selecionar imagem da capa do livro
            </label>
            <label class="custom-file-label" for="imageName" *ngIf="formGroup.controls['imageName'].valid">
              {{ formGroup.value.imageName ? formGroup.value.imageName : formGroup.value.imageSlug }}
            </label>
          </div>
          <div *ngIf="formGroup.controls['imageName'].touched && !formGroup.controls['imageName'].valid">
            <small class="form-text text-danger" *ngIf="formGroup.controls['imageName'].hasError('InvalidExtension')">
              Arquivo de imagem com extensão inválida (jpg, jpeg, png).
            </small>
          </div>
          <div *ngIf="!formGroup.controls['imageName'].valid">
            <small class="form-text text-danger" *ngIf="formGroup.controls['imageName'].hasError('InvalidImage')">
              Selecione a capa do livro.
            </small>
          </div>
        </div>

        <div class="form-group col-md-8">
          <label for="freightOption">Vc pagaria o frete? ❤️</label>
          <mat-button-toggle-group style="width: 100%" [value]="freightStart$ | async">
            <mat-button-toggle
              style="width: 100%"
              *ngFor="let option of freightOptions"
              [value]="option.text"
              (click)="onChangeFieldFreightOption(option.value)"
              >{{ option.text }}
            </mat-button-toggle>
          </mat-button-toggle-group>

          <div *ngIf="formGroup.controls['freightOption'].touched && !formGroup.controls['freightOption'].valid">
            <small class="form-text text-danger" *ngIf="formGroup.controls['freightOption'].hasError('required')">
              Escolha uma opções de frete.
            </small>
          </div>
        </div>

        <div class="form-group col-md-8">
          <label class="col-form-label" for="synopsis">Sinópse</label>
          <textarea
            formControlName="synopsis"
            id="synopsis"
            type="text"
            class="form-control"
            maxlength="2000"
            style="height: 300px"
          ></textarea>

          <div *ngIf="formGroup.controls['synopsis'].touched && !formGroup.controls['synopsis'].valid">
            <small class="form-text text-danger" *ngIf="formGroup.controls['synopsis'].hasError('maxlength')">
              A sinópse deve conter no máximo 2000 caracteres.
            </small>
          </div>
        </div>

        <div class="form-group col-md-8" *ngIf="!itsEditMode">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="agreeToTerms" id="agreeToTerms" />
            <label class="form-check-label" for="agreeToTerms">
              Declaro que estou ciente da importância desta doação e que irei tratar a mesma com carinho, evitando
              atrasos no processo. Sei que tem uma pessoa do outro lado que deseja e precisa desse livro.
            </label>
          </div>
        </div>

        <div class="form-group col-md-8" *ngIf="canApprove">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="approve" id="approve" />
            <label class="form-check-label" for="approve"> Aprovar essa doação. </label>
          </div>
        </div>

        <div class="form-group col-md-8">
          <div class="text-center" [hidden]="isLoading">
            <button
              type="submit"
              mat-flat-button
              color="primary"
              id="buttonSave"
              style="padding-left: 50px; padding-right: 50px"
              [disabled]="!formGroup.valid"
              [ngStyle]="{ cursor: !formGroup.valid ? 'not-allowed' : 'auto' }"
            >
              {{ buttonSaveLabel }}
            </button>
            <button mat-button color="primary" [routerLink]="['/book/list']">Cancelar</button>
          </div>
          <div class="text-center" [hidden]="!isLoading">
            <i class="fa fa-spinner fa-spin"></i> {{ isLoadingMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
