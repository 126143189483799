<!--navbar-fixed-->

<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <a class="navbar-brand" routerLink="/">
      <img src="{{ logoUrl }}" alt="logotipo" id="logotipo" class="d-inline-block align-top" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText" #menu>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <div class="mt-1">
            <app-input-search></app-input-search>
          </div>
        </li>
        <li class="nav-item">
          <!-- adicinando evento de click nos links que chama o metodo que esconde o menu -->
          <a class="nav-link hvr-underline-from-center" routerLink="/" (click)="showHideMenu()"
            >Início
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link hvr-underline-from-center" routerLink="/livros/doar" (click)="showHideMenu()"
            >Doe um Livro</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link hvr-underline-from-center" routerLink="/apoie-projeto" (click)="showHideMenu()"
            >Apoie o projeto</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link hvr-underline-from-center" routerLink="/quem-somos" (click)="showHideMenu()">Quem somos</a>
        </li>
        <li class="nav-item">
          <a *ngIf="userLogged" class="nav-link hvr-underline-from-center" routerLink="/panel" (click)="showHideMenu()"
            >Meu Painel</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link hvr-underline-from-center" routerLink="/contact-us" (click)="showHideMenu()"
            >Fale Conosco</a
          >
        </li>
        <li class="nav-item">
          <a *ngIf="!userLogged" class="nav-link hvr-underline-from-center" routerLink="/login" (click)="showHideMenu()"
            >Entrar</a
          >
          <a *ngIf="userLogged" class="nav-link hvr-underline-from-center" routerLink="/login" (click)="showHideMenu()"
            >Sair</a
          >
        </li>
      </ul>

      <div *ngIf="userLogged" id="welcomeUser">Olá, {{ shareBookUser.name }}</div>
    </div>
  </nav>
</div>

<!-- para afastar o miolo do menu. -->
<div style="width: 100%; height: 118px"></div>
