<form [formGroup]="formGroup" class="container form-horizontal" (ngSubmit)="resetPassword()">
  <h1 class="text-center display-4">Redefinir Senha</h1>
  <div class="form-row">
    <div class="col">
      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="email">E-mail</label>
        <div class="input-group">
          <input formControlName="email" id="txtEmail" type="text" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-envelope-square"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['email'].touched && !formGroup.controls['email'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('required')">
            E-mail obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('pattern')">
            O e-mail deve ser um e-mail válido.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3">
        <div class="text-center">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            style="padding-left: 50px; padding-right: 50px"
            [disabled]="!formGroup.valid"
          >
            Redefinir
          </button>
          <button [routerLink]="['/panel']" mat-button color="primary">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</form>
