<!--jumbotron-->

<img src="assets/img/banner3.jpeg" class="img-fluid w-100" alt="Escolha o livro. Leve para casa. Não custa nada." />

<div class="container">
  <h1 class="text-center display-4" *ngIf="!hasBook">
    Nenhum livro encontrado &#9785; <br />Ajude o Sharebook e
    <strong><a [routerLink]="['/livros/doar']">doe um livro agora</a></strong>
  </h1>
  <h1 class="text-center display-4" *ngIf="hasBook">Livros em destaque</h1>
  <div class="row justify-content-center book-covers" *ngIf="hasBook">
    <figure class="figure" *ngFor="let book of availableBooks">
      <div>
        <a href="/livros/{{ book.slug }}" title="Detalhes do Livro">
          <img src="{{ book.imageUrl }}" class="figure-img img-fluid rounded" alt="{{ book.title }}" />
        </a>
      </div>
      <figcaption class="figure-caption text-center">
        {{ book.title }}
      </figcaption>
    </figure>
  </div>

  <!-- PRÓXIMOS MEETUPS -->
  <ng-container *ngIf="meetupsUpcoming.length > 0">
    <h1 class="text-center display-4 meetup">Não perca os próximos Meetups!</h1>

    <app-card-meetup [meetup]="meetup" *ngFor="let meetup of meetupsUpcoming"></app-card-meetup>

    <div style="clear: both; height: 20px"></div>
  </ng-container>

  <!-- MEETUPS JÁ REALIZADOS -->
  <h1 class="text-center display-4 meetup">Meetups já realizados</h1>

  <app-card-meetup [meetup]="meetup" *ngFor="let meetup of meetups"></app-card-meetup>
  <div class="meetup" *ngIf="showButtonMoreMeetups">
    <button mat-flat-button color="primary" (click)="showMoreMetups()" style="margin-left: 82px">
      Carregar mais Meetups...
    </button>
  </div>

  <div style="clear: both; height: 20px"></div>
</div>
