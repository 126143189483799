<form [formGroup]="formGroup" class="container form-horizontal" (ngSubmit)="updateUser()">
  <h1 class="text-center display-4">Editar Perfil</h1>
  <div class="form-row">
    <div class="col">
      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="name">Nome</label>
        <div class="input-group">
          <input formControlName="name" name="name" type="text" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-address-book"></i>
          </div>
        </div>
        <div *ngIf="formGroup.controls['name'].touched && !formGroup.controls['name'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['name'].hasError('required')">
            Nome obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['name'].hasError('minlength')">
            O nome deve conter no mínimo 3 caracteres.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['name'].hasError('maxlength')">
            O nome deve conter no máximo 200 caracteres.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="email">E-mail</label>
        <div class="input-group">
          <input formControlName="email" type="text" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-envelope-square"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['email'].touched && !formGroup.controls['email'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('required')">
            E-mail obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['email'].hasError('pattern')">
            O e-mail deve ser um e-mail válido.
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="phone">DDD + Telefone</label>
        <div class="input-group">
          <input
            formControlName="phone"
            [dropSpecialCharacters]="false"
            mask="(00) 00000-0000"
            type="text"
            class="form-control"
          />

          <div class="input-group-addon append">
            <i class="fa fa-whatsapp"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['phone'].touched && !formGroup.controls['phone'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['phone'].hasError('required')">
            Telefone obrigatório.
          </small>
          <small class="form-text text-danger" *ngIf="formGroup.controls['phone'].hasError('pattern')">
            Telefone deve estar no formato (99) 99999-9999
          </small>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <label class="col-form-label" for="linkedin">LinkedIn</label>
        <div class="input-group">
          <input formControlName="linkedin" type="text" class="form-control" />
          <div class="input-group-addon append">
            <i class="fa fa-linkedin"></i>
          </div>
        </div>

        <div *ngIf="formGroup.controls['linkedin'].touched && !formGroup.controls['linkedin'].valid">
          <small class="form-text text-danger" *ngIf="formGroup.controls['linkedin'].hasError('pattern')">
            O LinkedIn deve ser uma URL para o seu perfil no LinkedIn.
          </small>
        </div>
      </div>

      <div formGroupName="Address">
        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="postalCode">CEP</label>
          <div class="input-group">
            <input
              formControlName="postalCode"
              [dropSpecialCharacters]="false"
              mask="00000-000"
              type="text"
              class="form-control"
              (blur)="getAddressByPostalCode($event.target.value)"
            />
            <button mat-flat-button color="primary" style="color: white;" (click)="onGetAddressByPostalCode(); (false)">
              <mat-icon>cached</mat-icon>
            </button>

            <div class="input-group-addon append" [hidden]="isGettingAddress">
              <i class="fa fa-map-marker"></i>
            </div>
            <div [hidden]="!isGettingAddress" [hidden]="!isGettingAddress">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          </div>

          <div
            *ngIf="
              formGroup['controls'].Address['controls'].postalCode.touched &&
              !formGroup['controls'].Address['controls'].postalCode.valid
            "
          >
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].postalCode.hasError('required')"
            >
              CEP obrigatório.
            </small>
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].postalCode.hasError('pattern')"
            >
              CEP deve estar no formato 99999-999.
            </small>
          </div>
        </div>

        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="street">Logradouro</label>
          <div class="input-group">
            <input formControlName="street" type="text" class="form-control" maxlength="80" />
            <div class="input-group-addon append">
              <i class="fa fa-road"></i>
            </div>
          </div>

          <div
            *ngIf="
              formGroup['controls'].Address['controls'].street.touched &&
              !formGroup['controls'].Address['controls'].street.valid
            "
          >
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].street.hasError('required')"
            >
              Endereço obrigatório.
            </small>
          </div>
        </div>

        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="number">Número</label>
          <div class="input-group">
            <input formControlName="number" type="text" class="form-control" maxlength="10" />
            <div class="input-group-addon append">
              <i class="fa fa-map-marker"></i>
            </div>
          </div>

          <div
            *ngIf="
              formGroup['controls'].Address['controls'].number.touched &&
              !formGroup['controls'].Address['controls'].number.valid
            "
          >
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].number.hasError('required')"
            >
              Número obrigatório.
            </small>
          </div>
        </div>

        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="complement">Complemento</label>
          <div class="input-group">
            <input formControlName="complement" type="text" class="form-control" maxlength="50" />
            <div class="input-group-addon append">
              <i class="fa fa-map-marker"></i>
            </div>
          </div>
        </div>

        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="neighborhood">Bairro</label>
          <div class="input-group">
            <input formControlName="neighborhood" type="text" class="form-control" maxlength="50" />
            <div class="input-group-addon append">
              <i class="fa fa-map-marker"></i>
            </div>
          </div>

          <div
            *ngIf="
              formGroup['controls'].Address['controls'].neighborhood.touched &&
              !formGroup['controls'].Address['controls'].neighborhood.valid
            "
          >
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].neighborhood.hasError('required')"
            >
              Bairro obrigatório.
            </small>
          </div>
        </div>

        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="city">Cidade</label>
          <div class="input-group">
            <input formControlName="city" type="text" class="form-control" maxlength="50" />
            <div class="input-group-addon append">
              <i class="fa fa-map-marker"></i>
            </div>
          </div>

          <div
            *ngIf="
              formGroup['controls'].Address['controls'].city.touched &&
              !formGroup['controls'].Address['controls'].city.valid
            "
          >
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].city.hasError('required')"
            >
              Cidade obrigatório.
            </small>
          </div>
        </div>

        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="state">Estado</label>
          <div class="input-group">
            <input formControlName="state" type="text" class="form-control" maxlength="30" />
            <div class="input-group-addon append">
              <i class="fa fa-map-marker"></i>
            </div>
          </div>

          <div
            *ngIf="
              formGroup['controls'].Address['controls'].state.touched &&
              !formGroup['controls'].Address['controls'].state.valid
            "
          >
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].state.hasError('required')"
            >
              Estado obrigatório.
            </small>
          </div>
        </div>

        <div class="form-group col-md-6 offset-md-3 mb-2">
          <label class="col-form-label" for="country">País</label>
          <div class="input-group">
            <input formControlName="country" type="text" class="form-control" maxlength="50" />
            <div class="input-group-addon append">
              <i class="fa fa-globe"></i>
            </div>
          </div>

          <div
            *ngIf="
              formGroup['controls'].Address['controls'].country.touched &&
              !formGroup['controls'].Address['controls'].country.valid
            "
          >
            <small
              class="form-text text-danger"
              *ngIf="formGroup['controls'].Address['controls'].country.hasError('required')"
            >
              País obrigatório.
            </small>
          </div>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3 mb-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="allowSendingEmail" id="defaultCheck1" />
          <label class="form-check-label" for="defaultCheck1"> Aceito receber e-mails e newletter do Sharebook </label>
        </div>
      </div>

      <div class="form-group col-md-6 offset-md-3">
        <div class="text-center">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            style="padding-left: 50px; padding-right: 50px"
            [disabled]="!formGroup.valid"
          >
            Atualizar
          </button>
          <a [routerLink]="['/panel']" mat-flat-button>Cancelar</a>
        </div>
      </div>
    </div>
  </div>
</form>
