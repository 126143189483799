<div class="container text-center">
  <h1 class="display-4">Painel</h1>
  <div class="flex-container justify-content-center">
    <div *ngIf="isAdmin" class="card">
      <a routerLink="/book/list" title="Editar livros">
        <img class="card-img-top panel-item" src="../../../assets/img/library.svg" alt="Card image" />
      </a>
      <div class="card-body">
        <h5 class="card-title">Gerenciar Livros</h5>
        <!--p class="card-text">Esta função permite gerenciar os livros para doação.</p-->
      </div>
    </div>

    <div class="card">
      <a routerLink="/myaccount" title="Editar perfil">
        <img class="card-img-top panel-item" src="../../../assets/img/learning.svg" alt="Card image" />
      </a>
      <div class="card-body">
        <h5 class="card-title">Minha Conta</h5>
      </div>
    </div>


    <div class="card">
      <a routerLink="/book/requesteds" title="Meus pedidos">
        <img class="card-img-top panel-item" src="../../../assets/img/person_book.png" alt="Card image" />
      </a>
      <div class="card-body">
        <h5 class="card-title">Meus pedidos</h5>
      </div>
    </div>

    <div class="card">
      <a routerLink="/book/donations" title="Minhas doações">
        <img class="card-img-top panel-item" src="../../../assets/img/send.svg" alt="Card image" />
      </a>
      <div class="card-body">
        <h5 class="card-title">Minhas doações</h5>
      </div>
    </div>
  </div>
</div>
